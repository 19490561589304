import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home'
  },

  {
    name: '官网首页',
    path: '/home',
    component: () => import('@/views/home/Home.vue')
    // children: [
  },
  {
    name: '关于我们',
    path: '/about',
    component: () => import('@/views/about/About.vue')
  },
  {
    name: '联系我们',
    path: '/privacy',
    component: () => import('@/views/privacy/Privacy.vue')
  },
  {
    name: '加入我们',
    path: '/join',
    component: () => import('@/views/join/Join.vue')
  }
];

const router = createRouter({
  routes,
  history: createWebHistory()
});

export default router;
